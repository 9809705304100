<script>
import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { getApi } from '@/api'

const mapMarker = require('../../../assets/images/icons/dot.png')

/**
 * Google-map component
 */
export default {
  page: {
    title: 'VRsteps World Map',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  components: {
    Layout,
    PageHeader
  },
  data () {
    return {
      title: 'VRsteps World Map',
      items: [
        {
          text: 'Admin',
          href: '/'
        },
        {
          text: 'VRsteps World Map',
          active: true
        }
      ],
      markers: [],
    }
  },
  methods: {
    ...getApi(),
  },

  mounted () {
    let userId = this.$store.state.user.id
    let accessToken = this.$store.state.user.access_token

    this.getPatientLog(userId, accessToken).then(response => response.json()).then(results =>
        this.markers = results.patient_log.filter(log => !!log.loc).map(log => {

          const position = {
            lat: Number(log.loc.split(',')[0]),
            lng: Number(log.loc.split(',')[1]),
          }

          if (position.lat && position.lng) {

            let mul = Math.ceil(Math.log(log.actions))
            return {
              position,
              marker: {
                url: mapMarker,
                size: {
                  width: 4 * mul,
                  height: 4 * mul,
                  f: 'px',
                  b: 'px',
                },
                scaledSize: {
                  width: 4 * mul,
                  height: 4 * mul,
                  f: 'px',
                  b: 'px',
                },
              },
              label: ' (' + log.actions + ')'
            }
          } else {
            return false
          }
        }).filter(log => !!log)
    )
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">User Map</h4>
            <p class="card-title-dsec">VRsteps used around the globe.</p>
            <!-- Map with markers -->
            <gmap-map ref="map" :center="{ lat: 11, lng: 12 }" :zoom="3" style="height: 640px">
              <gmap-marker
                  v-for="(m, index) in markers"
                  :key="index"
                  :position="m.position"
                  :clickable="true"
                  :draggable="false"
                  :icon="m.marker"
              ></gmap-marker>
            </gmap-map>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
